<template>
  <div class="Payment-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar
          v-if="Payment"
          :title="`Payment Methods`"
          :start="start"
          :end="end"
        />

        <v-container>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table
                  dense
                  sort-by="paymentMethod"
                  readonly
                  :headers="headers"
                  :itemsPerPage="Payment.length"
                  hide-default-footer
                  :items="Payment"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left text-capitalize">
                        {{ item.paymentMethod }}
                      </td>
                      <td class="text-right">
                        {{ item.totalPayment | currency }}
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:body.append="{ headers }"
                    v-if="Payment.length > 0"
                  >
                    <tr
                      :style="{ 'background-color': $style.listTotal.filter }"
                    >
                      <td
                        :colspan="headers.length - 3"
                        class="text-right font-weight-bold"
                      >
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import CategoryService from "@/modules/Category/service";
import paymentService from "@/modules/Customer/Payment/service";
// import Hashids from "hashids";
// const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null,
      isLoading: true,
      Payment: [],
      headers: [
        {
          text: "METHOD",
          align: "left",
          value: "paymentMethod",
          sortable: true,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "totalPayment",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    total() {
      let total = 0;
      this.Payment.map((row) => {
        total += row.totalPayment;
      });
      return total;
    },
  },
  async created() {
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    await this.getAll();
  },
  components: { ReportViewBar },
  methods: {
    getAll() {
      let where = {
        start: this.start,
        end: this.end,
      };

      return paymentService.groupByPaymentMethod(where).then((response) => {
        // console.log("response category", response);
        this.$Progress.finish();
        this.Payment = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
